import React, { useEffect, useRef, useState } from "react";
import { addDays, getDay } from "date-fns";

import AppButton from "../../UI/AppButtons";
import AppCard from "../../UI/AppCards";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { AppStandardMultiSelectCheckBox } from "../../UI/AppMultipleSelection/AppMultiSelection";
import BookingApi from "../../../API/Apis/BookingApi";
import { BookingDetails } from "../../../@types/Booking/bookingDetails";
import { BookingFormProps } from "./BookingForm.props";
import BookingGetters from "../../../store/onlineBooking/getters";
import { Location, LocationOpening } from "../../../@types/DTO/Locations";
import SearchIcon from "@mui/icons-material/Search";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import TrainIcon from "@mui/icons-material/Train";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { setTime } from "../../../helpers/dateManipulators";
import { useTranslation } from "react-i18next";
import { BookingSourceResponse } from "../../../@types/DTO/BookingSource";
import { t } from "i18next";
import { MacroClassGroup } from "../../../@types/DTO/MacroClass";
import { useSearchParams } from "react-router-dom";
import { formatTheme } from "../../../config/MuiColorThemes";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { TextResource } from "../../../@types/DTO/AppSetting";
import TagManager from "react-gtm-module";
import i18n from "../../../config/i18n";
import { string } from "prop-types";
function getEnabledTimeSlots(
  locationArray: Location[],
  locationName: string,
  date: Date | null
) {
  const AppSettings = BookingGetters.getAppSettings();
  const currentTime = new Date();

  const formatDate = (date) => {
    const d = new Date(date);
    d.setHours(currentTime.getHours());
    d.setMinutes(currentTime.getMinutes());
    d.setSeconds(currentTime.getSeconds());
    d.setMilliseconds(currentTime.getMilliseconds());
    return d.toISOString().split("T")[0];
  };
  const locationLang = i18n.language;

  return locationName && date
    ? locationArray
        .filter((e) => {
          const locationValue = AppSettings?.showLocationWebDescription
            ? locationLang === "it" && e.publicWebDescription
              ? e.publicWebDescription
              : locationLang === "en" &&
                e.publicWebDescriptionEN &&
                AppSettings?.showLocationWebDescription
              ? e.publicWebDescriptionEN
              : e.locationName
            : e.locationCode +
              "-" +
              e.locationName +
              " " +
              e.locationNumber +
              " " +
              e.locationAddress +
              " " +
              e.locationCity +
              " " +
              e.zipCode;
          return locationValue.toLowerCase() === locationName.toLowerCase();
        })[0]
        ?.openings.filter((e) => {
          const selectedDate = new Date(date);
          let isInValidPeriod = true;
          if (e?.validFrom != null && e?.validTo != null) {
            const validFrom = new Date(e?.validFrom);
            const validTo = new Date(e?.validTo);
            isInValidPeriod =
              selectedDate >= validFrom && selectedDate <= validTo;
          }
          return (
            isInValidPeriod &&
            (e.dayOfTheWeek % 7 == getDay(date) ||
              (e.specificDay && e.specificDay == formatDate(date)))
          );
        })
        .map((e) => {
          return {
            start: setTime(e.startTime, date),
            end: setTime(e.endTime, date),
          };
        })
    : [];
}

function getEnabledDropoffTimeSlots(
  locationArray: Location[],
  locationName: string,
  date: Date | null
) {
  const AppSettings = BookingGetters.getAppSettings();
  const currentTime = new Date();
  const formatDate = (date) => {
    const d = new Date(date);
    d.setHours(currentTime.getHours());
    d.setMinutes(currentTime.getMinutes());
    d.setSeconds(currentTime.getSeconds());
    d.setMilliseconds(currentTime.getMilliseconds());
    return d.toISOString().split("T")[0];
  };
  const locationLang = i18n.language;
  return locationName && date
    ? locationArray
        .filter((e) => {
          const locationValue = AppSettings?.showLocationWebDescription
            ? locationLang === "it" && e.publicWebDescription
              ? e.publicWebDescription
              : locationLang === "en" &&
                e.publicWebDescriptionEN &&
                AppSettings?.showLocationWebDescription
              ? e.publicWebDescriptionEN
              : e.locationName
            : e.locationCode +
              "-" +
              e.locationName +
              " " +
              e.locationNumber +
              " " +
              e.locationAddress +
              " " +
              e.locationCity +
              " " +
              e.zipCode;
          return locationValue.toLowerCase() === locationName.toLowerCase();
        })[0]
        ?.openings.filter((e) => {
          const selectedDate = new Date(date);
          let isInValidPeriod = true;
          if (e?.validFrom != null && e?.validTo != null) {
            const validFrom = new Date(e?.validFrom);
            const validTo = new Date(e?.validTo);
            isInValidPeriod =
              selectedDate >= validFrom && selectedDate <= validTo;
          }
          return (
            isInValidPeriod &&
            (e.dayOfTheWeek % 7 == getDay(date) ||
              (e.specificDay && e.specificDay == formatDate(date)))
          );
        })
        .map((e) => {
          return {
            start: setTime(e.dropoffStartTime, date),
            end: setTime(e.dropoffendTime, date),
          };
        })
    : [];
}

function BookingForm({
  onSubmit = () => {},
  className = "",
}: BookingFormProps): JSX.Element {
  const locationLang = i18n.language;
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const { t: translate } = useTranslation();
  const reduxBookingDetails: BookingDetails =
    BookingGetters.getBookingDetails();
  const AppSettings = BookingGetters.getAppSettings();
  const maxRentalAllowedDay = AppSettings?.maxRentalDurationInDays || 30;
  const [minRentalAllowedDay] = useState<number>(AppSettings?.minRentDays || 0);
  const [pickupLocation, setPickupLocation] = useState<string>("");
  const [reservationDestination, setReservationDestination] =
    useState<string>("");
  const [macroGroupOptions, setMacroGroupOptions] = useState<MacroClassGroup[]>(
    []
  );
  const [ageCriteriaData, setAgeCriteriaData] = useState([]);
  const [selectedMacroGroup, setSelectedMacroGroup] = useState<string>("");
  const [selectedMacroGroupWebCode, setSelectedMacroGroupWebCode] =
    useState<string>("");
  const [reservationStartDate, setStartDate] = useState<Date | null>(
    reduxBookingDetails.pickUpDateAndTime
      ? new Date(reduxBookingDetails.pickUpDateAndTime)
      : null
  );
  const [reservationEndDate, setEndDate] = useState<Date | null>(
    reduxBookingDetails.dropOffDateAndTime
      ? new Date(reduxBookingDetails.dropOffDateAndTime)
      : null
  );
  const [reservationPlatform, setReservationPlatform] = useState<string>(
    reduxBookingDetails.reservationSource
      ? reduxBookingDetails.reservationSource.name
      : ""
  );
  const [isDroppingAtSameLocation, setIsDroppingAtSameLocation] =
    useState<boolean>(
      reduxBookingDetails.dropAtSameLocation == null ||
        reduxBookingDetails.dropAtSameLocation == true
    );
  const [reservationSourceOptions, setReservationSourceOptions] = useState<
    BookingSourceResponse[]
  >([]);

  const [isYoungDriverAge, setIsYoungDriverAge] = useState<string>(
    reduxBookingDetails.isYoungDriverAge
      ? reduxBookingDetails.isYoungDriverAge
      : "false"
  );
  const [isSeniorDriverAge, setIsSeniorDriverAge] = useState<string>(
    reduxBookingDetails.isSeniorDriverAge
      ? reduxBookingDetails.isSeniorDriverAge
      : "false"
  );
  const [isNoFeeAge, setIsNoFeeAge] = useState<string>(
    reduxBookingDetails.noFeeAge ? reduxBookingDetails.noFeeAge : "false"
  );
  const [youngDriver2, setYoungDriver2] = useState<string>(
    reduxBookingDetails.isYoungDriver2Age
      ? reduxBookingDetails.isYoungDriver2Age
      : "false"
  );
  const [isAgeLimitSelected, setIsAgeLimitSelected] = useState<string>(
    reduxBookingDetails.isAgeLimitSelected
  );
  const [agreementCoupon, setagreementCoupon] = useState<string>(
    reduxBookingDetails.agreementCoupon
      ? reduxBookingDetails.agreementCoupon
      : ""
  );
  const [showModal, setShowModal] = useState(false);
  const [discountValueWithoutVat] = useState<any>(
    reduxBookingDetails.discountValueWithoutVat
      ? reduxBookingDetails.discountValueWithoutVat
      : "0"
  );
  const [locationDetail, setLocationDetail] = useState<Location[]>([]);
  const textResource = BookingGetters.getTextResource();
  const [codiceLabel, setCodiceLabel] = useState<String>("");
  const [pageTitleLabel, setPageTitleLabel] = useState<String>("");
  const [searchText, setSearchText] = useState<String>("");
  const [bookingReservationSource, setbookingReservationSource] = useState("");
  const [bookingResSourceForReservedArea, setBookingResSourceForReservedArea] =
    useState("");
  const [reservationSourceEnabled, setReservationSourceEnabled] =
    useState(false);
  const [reservationSourceForUser, setReservationSourceForUser] = useState<
    BookingSourceResponse[]
  >([]);
  const mountedRef = useRef(false);

  useEffect(() => {
    const userId: any = localStorage.getItem("userID");
    if (userId != null && userId != "null" && userId != "") {
      setReservationSourceEnabled(true);
      BookingApi.getReservationSourceForOnlineUser(userId).then((e) => {
        setReservationSourceForUser(e.data.reservationSourceList);
        setReservationPlatform(e.data.reservationSourceList[0]);
        mountedRef.current = true;
      });
    }
  }, []);

  useEffect(() => {
    const data: TextResource[] = textResource?.data ?? [];
    const bookingSearchTextData = data.find(
      (item) => item.key === "bookingSearchLabel"
    );
    const bookingReservationSourceData = data.find(
      (item) => item.key === "bookingReservationSource"
    );
    const bookingResSourceForReservedArea = data.find(
      (item) => item.key === "bookingResSourceForReservedArea"
    );
    const codiceVoucherLabel = data.find(
      (item) => item.key === "codiceVoucherLabel"
    );
    const TitleAtFirstPageLabel = data.find(
      (item) => item.key === "TitleAtFirstPageLabel"
    );
    setCodiceLabel(codiceVoucherLabel?.body || translate("Voucher Code"));
    setPageTitleLabel(TitleAtFirstPageLabel?.body || t("Movee Car Rental"));
    setSearchText(
      bookingSearchTextData
        ? bookingSearchTextData?.body
        : translate("Find vehicles")
    );
    setbookingReservationSource(
      bookingReservationSourceData
        ? bookingReservationSourceData.body
        : translate("reservation source")
    );
    setBookingResSourceForReservedArea(
      bookingResSourceForReservedArea
        ? bookingResSourceForReservedArea.body
        : translate("reservation source")
    );
  }, [textResource]);

  function requestParentResize() {
    const iframeHeight = AppSettings?.iframeHeightForFirstPage
      ? AppSettings?.iframeHeightForFirstPage
      : document.body.scrollHeight + 100;
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }

  const [tagManagerId, setTagManagerId] = useState("");
  const [isTagManagerEnabled, setIsTagManagerEnabled] = useState(false);
  const [smallScreen] = useState(window.innerWidth <= 768);
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  useEffect(() => {
    setIsTagManagerEnabled(!!AppSettings?.enableTagManager);
    setTagManagerId(AppSettings?.tagManagerId || "");
  }, [AppSettings]);
  useEffect(() => {
    if (isTagManagerEnabled && tagManagerId != "") {
      const tagManagerArgs = {
        gtmId: tagManagerId,
      };
      TagManager.initialize(tagManagerArgs); // google tag manager integrated along with the preferences
    }
  }, [tagManagerId, isTagManagerEnabled]);

  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize();
    }
  }, []);
  const [oldResStartDate, setOldResStartDate] = useState<Date | null>();
  const [oldResEndDate, setOldResEndDate] = useState<Date | null>();
  const currentTime = new Date();
  const formatDate = (date) => {
    const d = new Date(date);
    d.setHours(currentTime.getHours());
    d.setMinutes(currentTime.getMinutes());
    d.setSeconds(currentTime.getSeconds());
    d.setMilliseconds(currentTime.getMilliseconds());
    return d.toISOString().split("T")[0];
  };
  useEffect(() => {
    const openingSchedule: LocationOpening[] = [];
    const resStartDate =
      reservationStartDate != null ? new Date(reservationStartDate) : null;
    const resEndDate =
      reservationEndDate != null ? new Date(reservationEndDate) : null;
    if (resStartDate != null && resEndDate != null) {
      resStartDate.setHours(0, 0, 0, 0);
      resEndDate.setHours(0, 0, 0, 0);
      if (
        resStartDate.getTime() != oldResStartDate?.getTime() ||
        resEndDate.getTime() != oldResEndDate?.getTime()
      ) {
        setOldResStartDate(resStartDate);
        setOldResEndDate(resEndDate);
        for (const loc of locationDetail) {
          if (
            loc.locationName === reservationDestination ||
            loc.publicWebDescription == reservationDestination ||
            loc.publicWebDescriptionEN == reservationDestination
          ) {
            loc.openings.forEach((time) => {
              if (
                time.dayOfTheWeek % 7 == reservationEndDate?.getDay() ||
                (time.specificDay &&
                  time.specificDay == formatDate(reservationStartDate))
              ) {
                openingSchedule.push(time);
              }
            });
            break;
          }
        }
        for (const openings of openingSchedule) {
          if (
            openings?.isValidPeriod == "false" ||
            openings?.isValidPeriod == "null"
          ) {
            let dropOffTime;
            if (resStartDate.getTime() === resEndDate.getTime()) {
              dropOffTime = openings?.dropoffendTime;
            } else {
              dropOffTime = openings?.dropoffStartTime;
            }
            setEndDate(setTime(dropOffTime, reservationEndDate || new Date()));
            setStartDate(
              setTime(openings?.startTime, reservationStartDate || new Date())
            );
            break;
          } else if (
            openings?.validTo != null &&
            openings?.validFrom != null &&
            reservationEndDate &&
            reservationEndDate?.getTime() >
              new Date(openings?.validFrom).getTime() &&
            reservationEndDate?.getTime() <
              new Date(openings?.validTo).getTime()
          ) {
            let dropOffTime;
            if (resStartDate.getTime() === resEndDate.getTime()) {
              dropOffTime = openings?.dropoffendTime;
            } else {
              dropOffTime = openings?.dropoffStartTime;
            }
            setEndDate(setTime(dropOffTime, reservationEndDate || new Date()));
            setStartDate(
              setTime(openings?.startTime, reservationStartDate || new Date())
            );
            break;
          }
        }
        const currentDateWithoutTime = new Date();
        currentDateWithoutTime.setHours(0, 0, 0, 0);
        if (resStartDate.getTime() === currentDateWithoutTime.getTime()) {
          const currentDateWithTime = new Date();
          let hour = currentDateWithTime.getHours();
          const minutes = currentDateWithTime.getMinutes() < 30 ? 30 : 0;
          hour += currentDateWithTime.getMinutes() < 30 ? 0 : 1;
          setStartDate(
            setTime(hour + ":" + minutes, reservationStartDate || new Date())
          );
          if (resStartDate.getTime() == resEndDate.getTime()) {
            setEndDate(setTime(hour + 1 + ":" + 0, resEndDate || new Date()));
          }
        }
      }
    }
  }, [reservationStartDate, reservationEndDate]);

  useEffect(() => {
    BookingApi.getBookingAgeCriteria().then((response) => {
      const data = response?.data;
      if (data) {
        Object.keys(data).forEach((key) => {
          if (
            key === "youngDriver" ||
            key === "noAgeDriver" ||
            key === "seniorDriver" ||
            key === "youngDriver2"
          ) {
            setAgeCriteriaData((prevState) => ({
              ...prevState,
              [key]: data[key],
            }));
          }
        });
        if (data.selectYoungDriver) {
          setIsAgeLimitSelected("youngDriver");
        } else if (data.selectYoungDriver2) {
          setIsAgeLimitSelected("youngDriver2");
        } else if (data.selectNoAgeDriver) {
          setIsAgeLimitSelected("noAgeFeeDriver");
        } else if (data.selectSeniorDriver) {
          setIsAgeLimitSelected("seniorDriver");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (AppSettings?.showLocationWebDescription) {
      const locationDetails = reduxBookingDetails.pickUpLocationDetails;

      if (locationDetails) {
        const description =
          locationLang === "it"
            ? locationDetails.publicWebDescription || locationDetails.name
            : locationLang === "en"
            ? locationDetails.publicWebDescriptionEN || locationDetails.name
            : "";

        setPickupLocation(description);
      } else {
        setPickupLocation("");
      }
    } else {
      setPickupLocation(
        reduxBookingDetails.pickUpLocationDetails.name
          ? reduxBookingDetails.pickUpLocationDetails.code +
              "-" +
              reduxBookingDetails.pickUpLocationDetails.name +
              " " +
              reduxBookingDetails.pickUpLocationDetails.locationNumber +
              " " +
              reduxBookingDetails.pickUpLocationDetails.locationAddress +
              " " +
              reduxBookingDetails.pickUpLocationDetails.locationCity +
              " " +
              reduxBookingDetails.pickUpLocationDetails.zipCode
          : ""
      );
    }
    /*AppSettings?.showLocationWebDescription
      ? setReservationDestination(
          reduxBookingDetails.dropOffLocationDetails
            ? reduxBookingDetails.dropOffLocationDetails.publicWebDescription ||
                reduxBookingDetails.dropOffLocationDetails
                  .publicWebDescriptionEN ||
                reduxBookingDetails.dropOffLocationDetails.name
            : ""
        )*/
    if (AppSettings?.showLocationWebDescription) {
      const locationDetails = reduxBookingDetails.dropOffLocationDetails;

      if (locationDetails) {
        const description =
          locationLang === "it"
            ? locationDetails.publicWebDescription || locationDetails.name
            : locationLang === "en"
            ? locationDetails.publicWebDescriptionEN || locationDetails.name
            : "";

        setReservationDestination(description);
      } else {
        setReservationDestination("");
      }
    } else {
      setReservationDestination(
        reduxBookingDetails.dropOffLocationDetails.name
          ? reduxBookingDetails.dropOffLocationDetails.code +
              "-" +
              reduxBookingDetails.dropOffLocationDetails.name +
              " " +
              reduxBookingDetails.dropOffLocationDetails.locationNumber +
              " " +
              reduxBookingDetails.dropOffLocationDetails.locationAddress +
              " " +
              reduxBookingDetails.dropOffLocationDetails.locationCity +
              " " +
              reduxBookingDetails.dropOffLocationDetails.zipCode
          : ""
      );
    }
  }, [AppSettings, reduxBookingDetails]);
  useEffect(() => {
    if (isAgeLimitSelected === "youngDriver") {
      setIsYoungDriverAge("true");
      setIsSeniorDriverAge("false");
      setIsNoFeeAge("false");
      setYoungDriver2("false");
    } else if (
      isAgeLimitSelected === "noAgeFeeDriver" ||
      isAgeLimitSelected === "noAgeDriver"
    ) {
      setIsYoungDriverAge("false");
      setIsSeniorDriverAge("false");
      setIsNoFeeAge("true");
      setYoungDriver2("false");
    } else if (isAgeLimitSelected === "seniorDriver") {
      setIsYoungDriverAge("false");
      setIsSeniorDriverAge("true");
      setIsNoFeeAge("false");
      setYoungDriver2("false");
    } else if (isAgeLimitSelected === "youngDriver2") {
      setIsYoungDriverAge("false");
      setIsSeniorDriverAge("false");
      setIsNoFeeAge("false");
      setYoungDriver2("true");
    } else {
      setIsYoungDriverAge("false");
      setIsSeniorDriverAge("false");
      setIsNoFeeAge("false");
      setYoungDriver2("false");
    }
  }, [isAgeLimitSelected]);

  useEffect(() => {
    BookingApi.getReservationLocations().then((data: Record<string, any>) => {
      const sortByLocationName = (b, a) => {
        return b.locationName.localeCompare(a.locationName);
      };
      const sortedLocation = data.data.sort(sortByLocationName);
      setLocationDetail(sortedLocation || []);
    });
    BookingApi.getAllMacroGroups().then((e) => {
      setMacroGroupOptions(e);
    });
    BookingApi.getAllReservationSources().then((data) => {
      setReservationSourceOptions(data);
    });
  }, []);
  useEffect(() => {
    if (reservationPlatform === "") {
      const foundOption = reservationSourceOptions.find(
        (e) => e["Ragione Sociale textRS"]
      );
      const res = foundOption ? foundOption["Ragione Sociale textRS"] : "";
      setReservationPlatform(res);
    }
  }, [reservationSourceOptions]);

  const companyCode = process.env.REACT_APP_COMPANY_CODE == "loverent";
  // ----  set reservationSource from the query parameters for rentalPlusBooking  ----
  const [queryParams] = useSearchParams();
  useEffect(() => {
    const scriptChannel = queryParams.get("scriptChannel");
    const userId: any = localStorage.getItem("userID");
    if (!userId && scriptChannel && reservationSourceOptions.length > 0) {
      const reservationSource =
        reservationSourceOptions.find((e) => e["Codice"] === scriptChannel)?.[
          "Ragione Sociale textRS"
        ] || "";
      setReservationPlatform(reservationSource);
    }
  }, [reservationSourceOptions]);
  // ----------------------- END ----------------------------------

  const validateAgreementCoupon = AppSettings?.validateAgreementCoupon;

  const rateAmount = 0;
  const optionalAmount = 0;
  const totalAmount = 0;
  const refIdContext = 0;

  // Validation function for discount code with API call
  const validateDiscountCode = async (code) => {
    try {
      const response = await BookingApi.couponDiscount(
        code.trim(),
        new Date(reservationStartDate).toISOString(),
        new Date(reservationEndDate).toISOString(),
        rateAmount,
        optionalAmount,
        totalAmount,
        refIdContext
      );

      const status = response?.data?.status;
      // console.log("Coupon status:", status);

      if (status && status !== "error") {
        // console.log("Coupon applied successfully!");
        return true;
      } else {
        setShowModal(true);
        // console.log("Invalid coupon code.");
        return false;
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
      setShowModal(true);
      return false;
    }
  };

  return (
    <ThemeProvider theme={dynamicTheme}>
      <main
        className={`h-full ${
          companyCode ? "" : "flex justify-center"
        } box-border ${className}`}
      >
        <form
          onSubmit={async (e) => {
            // onSubmit={(e) => {
            e.preventDefault();
            if (agreementCoupon.trim() && validateAgreementCoupon) {
              const isCouponValid = await validateDiscountCode(agreementCoupon);
              if (!isCouponValid) {
                return;
              }
            }

            let selectedRS;
            if (reservationSourceEnabled) {
              selectedRS = reservationSourceForUser.find(
                (e) => e["description"] === reservationPlatform
              );
            } else {
              selectedRS = reservationSourceOptions.find(
                (e) => e["Ragione Sociale textRS"] === reservationPlatform
              );
            }
            const selectedPickupLocation = locationDetail.filter((e) => {
              const optionValue = AppSettings?.showLocationWebDescription
                ? locationLang === "it" && e.publicWebDescription
                  ? e.publicWebDescription
                  : locationLang === "en" &&
                    e.publicWebDescriptionEN &&
                    AppSettings?.showLocationWebDescription
                  ? e.publicWebDescriptionEN
                  : e.locationName
                : e.locationCode +
                  "-" +
                  e.locationName +
                  " " +
                  e.locationNumber +
                  " " +
                  e.locationAddress +
                  " " +
                  e.locationCity +
                  " " +
                  e.zipCode;
              return optionValue === pickupLocation;
            })[0];
            const selectedDropoffLocation = locationDetail.filter((e) => {
              const optionValue = AppSettings?.showLocationWebDescription
                ? locationLang === "it" && e.publicWebDescription
                  ? e.publicWebDescription
                  : locationLang === "en" &&
                    e.publicWebDescriptionEN &&
                    AppSettings?.showLocationWebDescription
                  ? e.publicWebDescriptionEN
                  : e.locationName
                : e.locationCode +
                  "-" +
                  e.locationName +
                  " " +
                  e.locationNumber +
                  " " +
                  e.locationAddress +
                  " " +
                  e.locationCity +
                  " " +
                  e.zipCode;
              return optionValue === reservationDestination;
            })[0];
            const bookingDetailsForLinkDirection: BookingDetails = {
              dropOffDateAndTime: reservationEndDate?.getTime() || Date.now(),
              dropOffLocationDetails: {
                name: reservationDestination,
                code: selectedDropoffLocation?.locationCode,
                locationCity: selectedDropoffLocation?.locationCity,
                locationAddress: selectedDropoffLocation?.locationAddress,
                locationNumber: selectedDropoffLocation?.locationNumber,
                zipCode: selectedDropoffLocation?.zipCode,
                publicWebDescription:
                  selectedDropoffLocation?.publicWebDescription,
                publicWebDescriptionEN:
                  selectedDropoffLocation?.publicWebDescriptionEN,
                reservationSourcePayNow: selectedRS?.payNow ?? false,
                reservationSourcePayLater: selectedRS?.payLater ?? false,
              },
              dropOffLocationName: reservationDestination,
              pickUpLocationDetails: {
                name: pickupLocation,
                code: selectedPickupLocation?.locationCode,
                locationCity: selectedPickupLocation?.locationCity,
                locationAddress: selectedPickupLocation?.locationAddress,
                locationNumber: selectedPickupLocation?.locationNumber,
                zipCode: selectedPickupLocation?.zipCode,
                publicWebDescription:
                  selectedPickupLocation?.publicWebDescription,
                publicWebDescriptionEN:
                  selectedPickupLocation?.publicWebDescriptionEN,
                reservationSourcePayNow: selectedRS?.payNow ?? false,
                reservationSourcePayLater: selectedRS?.payLater ?? false,
                isAirport: selectedPickupLocation?.isAirport,
              },
              pickUpLocationName: pickupLocation,
              pickUpDateAndTime: reservationStartDate?.getTime() || Date.now(),
              reservationSource: {
                name: reservationPlatform,
                code: selectedRS?.Codice || selectedRS?.code || "",
                reservationSourcePayNow: selectedRS?.payNow ?? false,
                reservationSourcePayLater: selectedRS?.payLater ?? false,
                locationCity: "",
                locationAddress: "",
                locationNumber: "",
                zipCode: "",
                publicWebDescription: "",
                publicWebDescriptionEN: "",
              },
              agreementCoupon: agreementCoupon.trim(),
              discountValueWithoutVat: discountValueWithoutVat,
              macroGroupName: selectedMacroGroupWebCode,
              showPics: true,
              showOptionalImage: true,
              showVehicleParameter: true,
              showVehicleExtraImage: true,
              showBookingDiscount: true,
              isYoungDriverAge: isYoungDriverAge,
              isYoungDriver2Age: youngDriver2,
              isSeniorDriverAge: isSeniorDriverAge,
              noFeeAge: isNoFeeAge,
              isAgeLimitSelected: isAgeLimitSelected,
              payLaterFeeAmt: "",
              isPayLaterFee: false,
              dropAtSameLocation: isDroppingAtSameLocation,
              reservationSourcePayNow: selectedRS?.payNow ?? false,
              reservationSourcePayLater: selectedRS?.payLater ?? false,
              youngDriverAgeValue: ageCriteriaData?.["youngDriver"],
              youngDriver2AgeValue: ageCriteriaData?.["youngDriver2"],
              seniorDriverAgeValue: ageCriteriaData?.["seniorDriver"],
              noAgeDriverValue: ageCriteriaData?.["noAgeDriver"],
            };
            onSubmit(bookingDetailsForLinkDirection);
          }}
        >
          {AppSettings?.enableHorizontalView ? (
            <AppCard className="flex flex-col bg-white w-full box-border justify-center items-center p-4 gap-4 py-8">
              <div className="gap-6 flex-col w-full">
                <div className="">
                  <div style={{ margin: "12px" }}>
                    <AppInputSwitcher
                      required={true}
                      className={"w-full"}
                      value={pickupLocation}
                      label={translate("pick up location")}
                      key={"pickUpLocation"}
                      options={locationDetail?.map((e: Location) => {
                        const optionValue =
                          AppSettings?.showLocationWebDescription
                            ? locationLang === "it" && e.publicWebDescription
                              ? e.publicWebDescription
                              : locationLang === "en" &&
                                e.publicWebDescriptionEN &&
                                AppSettings?.showLocationWebDescription
                              ? e.publicWebDescriptionEN
                              : e.locationName
                            : e.locationCode +
                              "-" +
                              e.locationName +
                              " " +
                              e.locationNumber +
                              " " +
                              e.locationAddress +
                              " " +
                              e.locationCity +
                              " " +
                              e.zipCode;
                        let icon = <LocationCityIcon color="primary" />;
                        if (e.isAirport) {
                          icon = <FlightTakeoffIcon htmlColor="blue" />;
                        } else if (e.isRailway) {
                          icon = <TrainIcon htmlColor="green" />;
                        }
                        return {
                          label: optionValue,
                          value: optionValue,
                          icon,
                        };
                      })}
                      type="select"
                      variant="with-search"
                      onchange={(e) => {
                        if (isDroppingAtSameLocation) {
                          setReservationDestination(e);
                        }
                        setPickupLocation(e);
                        setStartDate(null);
                        setEndDate(null);
                      }}
                    />
                  </div>
                  {!isDroppingAtSameLocation && (
                    <div style={{ margin: "12px" }}>
                      <AppInputSwitcher
                        required={true}
                        value={reservationDestination}
                        label={translate("drop off location")}
                        key={"dropOffLocation"}
                        options={locationDetail.map((e) => {
                          const optionValue =
                            AppSettings?.showLocationWebDescription
                              ? locationLang === "it" && e.publicWebDescription
                                ? e.publicWebDescription
                                : locationLang === "en" &&
                                  e.publicWebDescriptionEN &&
                                  AppSettings?.showLocationWebDescription
                                ? e.publicWebDescriptionEN
                                : e.locationName
                              : e.locationCode +
                                "-" +
                                e.locationName +
                                " " +
                                e.locationNumber +
                                " " +
                                e.locationAddress +
                                " " +
                                e.locationCity +
                                " " +
                                e.zipCode;
                          return {
                            label: optionValue,
                            value: optionValue,
                          };
                        })}
                        type="select"
                        variant="with-search"
                        onchange={(e) => {
                          setReservationDestination(e);
                          setEndDate(null);
                        }}
                      />
                    </div>
                  )}
                  {AppSettings?.locationReturnEnable && (
                    <div style={{ margin: "12px" }}>
                      <div className="min-w-fit max-w-fit">
                        <AppStandardMultiSelectCheckBox
                          value={[
                            isDroppingAtSameLocation
                              ? translate("drop at same location")
                              : "",
                          ]}
                          onChange={(e) => {
                            setIsDroppingAtSameLocation(
                              !isDroppingAtSameLocation
                            );
                            if (e.length) {
                              setReservationDestination(pickupLocation);
                            }
                          }}
                          options={[translate("drop at same location")]}
                          color={
                            AppSettings?.secondaryAsBackgroundForButtons
                              ? "secondaryColor"
                              : "primary"
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ margin: "12px" }}>
                  <AppInputSwitcher
                    disableOnDay={
                      pickupLocation && locationDetail.length
                        ? Object.keys(
                            locationDetail
                              .filter((e) => {
                                const optionValue =
                                  AppSettings?.showLocationWebDescription
                                    ? locationLang === "it" &&
                                      e.publicWebDescription
                                      ? e.publicWebDescription
                                      : locationLang === "en" &&
                                        e.publicWebDescriptionEN &&
                                        AppSettings?.showLocationWebDescription
                                      ? e.publicWebDescriptionEN
                                      : e.locationName
                                    : e.locationCode +
                                      "-" +
                                      e.locationName +
                                      " " +
                                      e.locationNumber +
                                      " " +
                                      e.locationAddress +
                                      " " +
                                      e.locationCity +
                                      " " +
                                      e.zipCode;
                                return (
                                  optionValue?.toLowerCase() ===
                                  pickupLocation?.toLowerCase()
                                );
                              })[0]
                              ?.openings?.map((e) => {
                                return e.dayOfTheWeek % 7;
                              })
                              .reduce(
                                (e, v) => {
                                  delete e[v];
                                  return e;
                                },
                                {
                                  1: true,
                                  2: true,
                                  3: true,
                                  4: true,
                                  5: true,
                                  6: true,
                                  7: true,
                                }
                              )
                          ).map((e) => parseInt(e))
                        : []
                    }
                    required={true}
                    className="w-full grow md:min-w-72"
                    label={translate("reservation dates")}
                    key={"reservationDates"}
                    options={[]}
                    startDate={reservationStartDate}
                    endDate={reservationEndDate}
                    minDate={addDays(new Date(), minRentalAllowedDay)}
                    maxDate={
                      reservationStartDate
                        ? addDays(
                            new Date(reservationStartDate?.toString()),
                            maxRentalAllowedDay
                          )
                        : addDays(new Date(), 365)
                    }
                    type="date"
                    variant="range"
                    onchange={(selectedDateRange) => {
                      if (selectedDateRange[0]) {
                        const selectedLocation = locationDetail.filter((e) => {
                          const optionValue =
                            AppSettings?.showLocationWebDescription
                              ? locationLang === "it" && e.publicWebDescription
                                ? e.publicWebDescription
                                : locationLang === "en" &&
                                  e.publicWebDescriptionEN &&
                                  AppSettings?.showLocationWebDescription
                                ? e.publicWebDescriptionEN
                                : e.locationName
                              : e.locationCode +
                                "-" +
                                e.locationName +
                                " " +
                                e.locationNumber +
                                " " +
                                e.locationAddress +
                                " " +
                                e.locationCity +
                                " " +
                                e.zipCode;
                          return (
                            optionValue?.toLowerCase() ===
                            pickupLocation?.toLowerCase()
                          );
                        })[0];

                        if (selectedLocation) {
                          const opening = selectedLocation.openings.filter(
                            (e) =>
                              getDay(selectedDateRange[0]) ===
                              e.dayOfTheWeek % 7
                          )[0];

                          const startTime = opening
                            ? opening.LocationStartTime || opening.startTime
                            : "";

                          setStartDate(
                            setTime(startTime, selectedDateRange[0])
                          );
                        }
                      } else {
                        setStartDate(null);
                      }
                      if (selectedDateRange[1]) {
                        const selectedLocation = locationDetail.filter((e) => {
                          const optionValue =
                            AppSettings?.showLocationWebDescription
                              ? locationLang === "it" && e.publicWebDescription
                                ? e.publicWebDescription
                                : locationLang === "en" &&
                                  e.publicWebDescriptionEN &&
                                  AppSettings?.showLocationWebDescription
                                ? e.publicWebDescriptionEN
                                : e.locationName
                              : e.locationCode +
                                "-" +
                                e.locationName +
                                " " +
                                e.locationNumber +
                                " " +
                                e.locationAddress +
                                " " +
                                e.locationCity +
                                " " +
                                e.zipCode;
                          return (
                            optionValue?.toLowerCase() ===
                            reservationDestination.toLowerCase()
                          );
                        })[0];

                        if (selectedLocation) {
                          const opening = selectedLocation.openings.filter(
                            (e) =>
                              getDay(selectedDateRange[1]) ===
                              e.dayOfTheWeek % 7
                          )[0];

                          const endTime = opening
                            ? opening.LocationStartTime || opening.startTime
                            : "";

                          setEndDate(setTime(endTime, selectedDateRange[1]));
                        }
                      } else {
                        setEndDate(null);
                      }
                    }}
                  />{" "}
                </div>
                <div
                  style={{
                    margin: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <AppInputSwitcher
                    required={true}
                    disabled={!reservationStartDate}
                    className="w-[49%] min-w-[5rem]"
                    label={translate("pick up time")}
                    key={"pickUpTime"}
                    enableTimesInRange={getEnabledTimeSlots(
                      locationDetail,
                      pickupLocation,
                      reservationStartDate
                    )}
                    value={reservationStartDate}
                    options={[]}
                    type="time"
                    variant="slot"
                    onchange={(e) => {
                      setStartDate(e);
                    }}
                  />
                  <AppInputSwitcher
                    enableTimesInRange={getEnabledDropoffTimeSlots(
                      locationDetail,
                      reservationDestination,
                      reservationEndDate
                    )}
                    disabled={!reservationEndDate}
                    required={true}
                    className="w-[49%] min-w-[5rem]"
                    label={translate("drop off time")}
                    key={"dropOffTime"}
                    options={[]}
                    value={reservationEndDate}
                    type="time"
                    variant="slot"
                    onchange={(e) => {
                      setEndDate(e);
                    }}
                  />
                </div>
                {/* {AppSettings?.showMacroGroupSelection && (
                  <div style={{ margin: "12px" }}>
                    <AppInputSwitcher
                      required={false}
                      className={"w-full"}
                      type="select"
                      variant="with-search"
                      label={translate("Macro Group")}
                      options={macroGroupOptions?.map((e) => e.label) || []}
                      value={selectedMacroGroup}
                      onchange={(e) => {
                        setSelectedMacroGroup(e);
                      }}
                    />
                  </div>
                )}
*/}
                {AppSettings?.showMacroGroupSelection && (
                  <AppInputSwitcher
                    required={false}
                    className={"w-full"}
                    type="select"
                    variant="with-search"
                    label={translate("Macro Group")}
                    options={macroGroupOptions?.map((e) => e.label) || []}
                    value={selectedMacroGroup}
                    onchange={(e) => {
                      const selectedOption = macroGroupOptions.find(
                        (option) => option.label === e
                      );
                      if (selectedOption) {
                        setSelectedMacroGroup(selectedOption.label);
                        setSelectedMacroGroupWebCode(selectedOption.WebCode);
                      }
                    }}
                  />
                )}
                {mountedRef.current &&
                reservationSourceEnabled === true &&
                reservationSourceForUser.length > 0 ? (
                  <div className="flex" style={{ margin: "12px" }}>
                    <AppInputSwitcher
                      required={true}
                      className="w-full min-w-[12rem]"
                      label={bookingResSourceForReservedArea}
                      key={"reservationPlatform"}
                      options={reservationSourceForUser?.map(
                        (e) => e["description"]
                      )}
                      type="select"
                      variant="with-search"
                      onchange={(e) => {
                        setReservationPlatform(e);
                      }}
                      value={reservationPlatform}
                    />
                  </div>
                ) : (
                  AppSettings?.showReservationGroupSelection &&
                  !reservationSourceEnabled && (
                    <div className="flex" style={{ margin: "12px" }}>
                      <AppInputSwitcher
                        required={true}
                        className="w-full min-w-[12rem]"
                        label={bookingReservationSource}
                        key={"reservationPlatform"}
                        options={reservationSourceOptions?.map(
                          (e) => e["Ragione Sociale textRS"]
                        )}
                        value={reservationPlatform}
                        type="select"
                        variant="with-search"
                        onchange={(e) => {
                          setReservationPlatform(e);
                        }}
                      />
                    </div>
                  )
                )}
                {AppSettings?.showWebCodeInput && (
                  <div style={{ margin: "12px" }}>
                    <AppInputSwitcher
                      required={false}
                      autoComplete="off"
                      className="w-[49%] min-w-[5rem]"
                      label={codiceLabel}
                      key={"Voucher Code"}
                      placeholder={codiceLabel}
                      options={[]}
                      value={agreementCoupon}
                      type="text"
                      variant="slot"
                      onchange={(e) => {
                        setagreementCoupon(e);
                      }}
                    />
                  </div>
                )}
                {showModal && validateAgreementCoupon && (
                  <div
                    className="modal-overlay"
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: "1001",
                    }}
                  >
                    <div
                      className="modal-content"
                      style={{
                        background: "white",
                        width: "300px",
                        padding: "15px",
                        borderRadius: "8px",
                        textAlign: "center",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        zIndex: "1000",
                      }}
                    >
                      <p>
                        {translate(
                          "The discount code you have inserted is not valid.Please insert a valid discount code to proceed."
                        )}
                      </p>
                      <button
                        onClick={() => setShowModal(false)}
                        className="close-button"
                        style={{
                          backgroundColor: "#17a2b8",
                          color: "white",
                          border: "none",
                          padding: "10px 20px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        {translate("Close")}
                        {/*Close*/}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="gap-2 w-full ml-[30px]">
                <div>
                  {Object.entries(ageCriteriaData)
                    .map(([key, value]) => {
                      switch (key.toUpperCase()) {
                        case "YOUNGDRIVER":
                        case "YOUNGDRIVER2":
                        case "NOAGEDRIVER":
                        case "SENIORDRIVER":
                          return (
                            <div key={key} style={{ marginBottom: "6px" }}>
                              <AppRadioButton
                                required={true}
                                color={
                                  AppSettings?.secondaryAsBackgroundForButtons
                                    ? "secondaryColor"
                                    : "primary"
                                }
                                value={isAgeLimitSelected}
                                className="w-full text-xs"
                                onChange={(e) => {
                                  setIsAgeLimitSelected(e);
                                }}
                                options={[
                                  {
                                    label: t(value),
                                    value: key,
                                  },
                                ]}
                              />
                            </div>
                          );
                        default:
                          return null;
                      }
                    })
                    .filter((option) => option !== null)}
                </div>
                <div style={{ marginRight: "20px" }}>
                  <AppButton
                    type={"submit"}
                    endIcon={<SearchIcon />}
                    className="w-[100%] h-14 w-full"
                    color={
                      AppSettings?.secondaryAsBackgroundForButtons
                        ? "secondaryColor"
                        : "primary"
                    }
                  >
                    <p className="w-full text-center">{searchText}</p>
                  </AppButton>
                </div>
              </div>
            </AppCard>
          ) : process.env.REACT_APP_COMPANY_CODE == "loverent" ? (
            <>
              {AppSettings?.pageTitle && (
                <>
                  <span className="text-white font-bold text-xl">
                    {t("Book Online")}
                  </span>
                  <span className="text-white font-bold text-xl">-</span>
                  <span className="text-white font-bold text-xl">
                    {pageTitleLabel}
                  </span>
                  <br />
                  <br />
                </>
              )}
              <AppCard className="flex flex-col bg-white w-full box-border justify-center items-center p-4 gap-4 py-8">
                <div className="flex flex-col md:flex-row gap-2 w-full">
                  <div className="w-full grow md:min-w-72 md:w-[500px]">
                    <AppInputSwitcher
                      required={true}
                      className={"w-full"}
                      value={pickupLocation}
                      label={translate("pick up location")}
                      key={"pickUpLocation"}
                      options={locationDetail?.map((e: Location) => {
                        const optionValue =
                          AppSettings?.showLocationWebDescription
                            ? locationLang === "it" && e.publicWebDescription
                              ? e.publicWebDescription
                              : locationLang === "en" &&
                                e.publicWebDescriptionEN &&
                                AppSettings?.showLocationWebDescription
                              ? e.publicWebDescriptionEN
                              : e.locationName
                            : e.locationCode +
                              " - " +
                              e.locationName +
                              " " +
                              e.locationNumber +
                              " " +
                              e.locationAddress +
                              " " +
                              e.locationCity +
                              " " +
                              e.zipCode;

                        let icon = <LocationCityIcon color="primary" />;
                        if (e.isAirport) {
                          icon = <FlightTakeoffIcon htmlColor="blue" />;
                        } else if (e.isRailway) {
                          icon = <TrainIcon htmlColor="green" />;
                        }
                        return {
                          label: optionValue,
                          value: optionValue,
                          icon,
                        };
                      })}
                      type="select"
                      variant={
                        AppSettings?.notOpenKeyboardForLocation && smallScreen
                          ? "with-search-Location"
                          : "with-search"
                      }
                      onchange={(e) => {
                        if (isDroppingAtSameLocation) {
                          setReservationDestination(e);
                        }
                        setPickupLocation(e);
                        setStartDate(null);
                        setEndDate(null);
                      }}
                    />
                  </div>
                  {!isDroppingAtSameLocation && (
                    <div className=" w-full md:min-w-50 md:w-[50px]">
                      <AppInputSwitcher
                        required={true}
                        value={reservationDestination}
                        label={translate("drop off location")}
                        key={"dropOffLocation"}
                        options={locationDetail.map((e) => {
                          const optionValue =
                            AppSettings?.showLocationWebDescription
                              ? locationLang === "it" && e.publicWebDescription
                                ? e.publicWebDescription
                                : locationLang === "en" &&
                                  e.publicWebDescriptionEN &&
                                  AppSettings?.showLocationWebDescription
                                ? e.publicWebDescriptionEN
                                : e.locationName
                              : e.locationCode +
                                "-" +
                                e.locationName +
                                " " +
                                e.locationNumber +
                                " " +
                                e.locationAddress +
                                " " +
                                e.locationCity +
                                " " +
                                e.zipCode;
                          let icon = <LocationCityIcon color="primary" />;
                          if (e.isAirport) {
                            icon = <FlightTakeoffIcon htmlColor="blue" />;
                          } else if (e.isRailway) {
                            icon = <TrainIcon htmlColor="green" />;
                          }
                          return {
                            label: optionValue,
                            value: optionValue,
                            icon,
                          };
                        })}
                        type="select"
                        variant="with-search"
                        onchange={(e) => {
                          setReservationDestination(e);
                          setEndDate(null);
                        }}
                      />
                    </div>
                  )}
                  <div className="w-full grow md:min-w-72 md:w-[500px]">
                    <AppInputSwitcher
                      sx={{
                        width: "100%", // Ensures full width
                        svg: { color: "primary" },
                        input: { color: "primary" },
                        label: { color: "primary" },
                        backgroundColor: "primary",
                      }}
                      disableOnDay={
                        pickupLocation && locationDetail.length
                          ? Object.keys(
                              locationDetail
                                .filter((e) => {
                                  const optionValue =
                                    AppSettings?.showLocationWebDescription
                                      ? locationLang === "it" &&
                                        e.publicWebDescription
                                        ? e.publicWebDescription
                                        : locationLang === "en" &&
                                          e.publicWebDescriptionEN &&
                                          AppSettings?.showLocationWebDescription
                                        ? e.publicWebDescriptionEN
                                        : e.locationName
                                      : e.locationCode +
                                        "-" +
                                        e.locationName +
                                        " " +
                                        e.locationNumber +
                                        " " +
                                        e.locationAddress +
                                        " " +
                                        e.locationCity +
                                        " " +
                                        e.zipCode;
                                  return (
                                    optionValue?.toLowerCase() ===
                                    pickupLocation?.toLowerCase()
                                  );
                                })[0]
                                ?.openings?.map((e) => {
                                  return e.dayOfTheWeek % 7;
                                })
                                .reduce(
                                  (e, v) => {
                                    delete e[v];
                                    return e;
                                  },
                                  {
                                    1: true,
                                    2: true,
                                    3: true,
                                    4: true,
                                    5: true,
                                    6: true,
                                    7: true,
                                  }
                                )
                            ).map((e) => parseInt(e))
                          : []
                      }
                      required={true}
                      className="w-full grow md:min-w-72"
                      label={translate("reservation dates")}
                      key={"reservationDates"}
                      options={[]}
                      startDate={reservationStartDate}
                      endDate={reservationEndDate}
                      minDate={addDays(new Date(), minRentalAllowedDay)}
                      maxDate={
                        reservationStartDate
                          ? addDays(
                              new Date(reservationStartDate?.toString()),
                              maxRentalAllowedDay
                            )
                          : addDays(new Date(), 365)
                      }
                      type="date"
                      variant="range"
                      onchange={(selectedDateRange) => {
                        if (selectedDateRange[0]) {
                          setStartDate(
                            setTime(
                              locationDetail
                                .filter((e) => {
                                  const optionValue =
                                    AppSettings?.showLocationWebDescription
                                      ? locationLang === "it" &&
                                        e.publicWebDescription
                                        ? e.publicWebDescription
                                        : locationLang === "en" &&
                                          e.publicWebDescriptionEN &&
                                          AppSettings?.showLocationWebDescription
                                        ? e.publicWebDescriptionEN
                                        : e.locationName
                                      : e.locationCode +
                                        "-" +
                                        e.locationName +
                                        " " +
                                        e.locationNumber +
                                        " " +
                                        e.locationAddress +
                                        " " +
                                        e.locationCity +
                                        " " +
                                        e.zipCode;
                                  return (
                                    optionValue?.toLowerCase() ===
                                    pickupLocation?.toLowerCase()
                                  );
                                })[0]
                                ?.openings.filter(
                                  (e) =>
                                    getDay(selectedDateRange[0]) ===
                                    e.dayOfTheWeek % 7
                                )[0]?.startTime || "",
                              selectedDateRange[0]
                            )
                          );
                        } else {
                          setStartDate(null);
                        }
                        if (selectedDateRange[1]) {
                          setEndDate(
                            setTime(
                              locationDetail
                                .filter((e) => {
                                  const optionValue =
                                    AppSettings?.showLocationWebDescription
                                      ? locationLang === "it" &&
                                        e.publicWebDescription
                                        ? e.publicWebDescription
                                        : locationLang === "en" &&
                                          e.publicWebDescriptionEN &&
                                          AppSettings?.showLocationWebDescription
                                        ? e.publicWebDescriptionEN
                                        : e.locationName
                                      : e.locationCode +
                                        "-" +
                                        e.locationName +
                                        " " +
                                        e.locationNumber +
                                        " " +
                                        e.locationAddress +
                                        " " +
                                        e.locationCity +
                                        " " +
                                        e.zipCode;
                                  return (
                                    optionValue?.toLowerCase() ===
                                    reservationDestination.toLowerCase()
                                  );
                                })[0]
                                ?.openings.filter(
                                  (e) =>
                                    getDay(selectedDateRange[1]) ===
                                    e.dayOfTheWeek % 7
                                )[0]?.startTime || "",
                              selectedDateRange[1]
                            )
                          );
                        } else {
                          setEndDate(null);
                        }
                      }}
                    />{" "}
                  </div>
                </div>
                <div className="md:grow flex flex-col md:flex-row gap-2 min-w-[0rem] md:min-w-[25rem] w-full">
                  <div className="w-full flex-1 md:min-w-72 md:w-auto">
                    <AppInputSwitcher
                      required={true}
                      disabled={!reservationStartDate}
                      className="w-full  min-w-[10rem]"
                      label={translate("pick up time")}
                      key={"pickUpTime"}
                      enableTimesInRange={getEnabledTimeSlots(
                        locationDetail,
                        pickupLocation,
                        reservationStartDate
                      )}
                      value={reservationStartDate}
                      options={[]}
                      type="time"
                      variant="slot"
                      onchange={(e) => {
                        setStartDate(e);
                      }}
                    />
                  </div>
                  <div className="w-full flex-1 md:min-w-72 md:w-auto">
                    <AppInputSwitcher
                      enableTimesInRange={getEnabledDropoffTimeSlots(
                        locationDetail,
                        reservationDestination,
                        reservationEndDate
                      )}
                      disabled={!reservationEndDate}
                      required={true}
                      className="min-w-[10rem]"
                      label={translate("drop off time")}
                      key={"dropOffTime"}
                      options={[]}
                      value={reservationEndDate}
                      type="time"
                      variant="slot"
                      onchange={(e) => {
                        setEndDate(e);
                      }}
                    />
                  </div>
                </div>
                <div className="md:grow flex flex-col md:flex-row gap-2 min-w-[0rem] md:min-w-[25rem] w-full">
                  {AppSettings?.showMacroGroupSelection && (
                    <div className="w-full grow md:min-w-72 md:w-[500px]">
                      <AppInputSwitcher
                        required={false}
                        className={"w-full min-w-[12rem]"}
                        type="select"
                        variant="with-search"
                        label={translate("Macro Group")}
                        options={macroGroupOptions?.map((e) => e.label) || []}
                        value={selectedMacroGroup}
                        onchange={(e) => {
                          setSelectedMacroGroup(e);
                        }}
                      />
                    </div>
                  )}
                  <div
                    className={`w-full md:min-w-72 ${
                      AppSettings?.showMacroGroupSelection
                        ? ""
                        : " md:w-[500px]"
                    }`}
                  >
                    {AppSettings?.showReservationGroupSelection && (
                      <AppInputSwitcher
                        required={true}
                        className="w-full grow min-w-[12rem] md:min-w-[44rem]"
                        label={translate("reservation source")}
                        key={"reservationPlatform"}
                        options={reservationSourceOptions?.map(
                          (e) => e["Ragione Sociale"]
                        )}
                        value={reservationPlatform}
                        type="select"
                        variant="with-search"
                        onchange={(e) => {
                          setReservationPlatform(e);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="md:grow flex flex-col md:flex-row gap-2 min-w-[0rem] md:min-w-[25rem] w-full">
                  <div className="w-full md:min-w-72 md:w-[500px]">
                    {AppSettings?.showWebCodeInput && (
                      <AppInputSwitcher
                        required={false}
                        autoComplete="off"
                        className="min-w-[10rem]"
                        label={translate("Voucher Code")}
                        key={"Voucher Code"}
                        placeholder={translate("Voucher Code")}
                        options={[]}
                        value={agreementCoupon}
                        type="text"
                        variant="slot"
                        onchange={(e) => {
                          setagreementCoupon(e);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-2 w-full flex-wrap">
                  {AppSettings?.locationReturnEnable && (
                    <div className="min-w-fit max-w-fit">
                      <AppStandardMultiSelectCheckBox
                        value={[
                          isDroppingAtSameLocation
                            ? translate("drop at same location")
                            : "",
                        ]}
                        onChange={(e) => {
                          setIsDroppingAtSameLocation(
                            !isDroppingAtSameLocation
                          );
                          if (e.length) {
                            setReservationDestination(pickupLocation);
                          }
                        }}
                        options={[translate("drop at same location")]}
                        color={
                          AppSettings?.secondaryAsBackgroundForButtons
                            ? "secondaryColor"
                            : "primary"
                        }
                      />
                    </div>
                  )}
                  <div>
                    <AppRadioButton
                      required={true}
                      value={isAgeLimitSelected}
                      color={
                        AppSettings?.secondaryAsBackgroundForButtons
                          ? "secondaryColor"
                          : "primary"
                      }
                      onChange={(e) => {
                        setIsAgeLimitSelected(e);
                      }}
                      options={Object.entries(ageCriteriaData)
                        .map(([key, value]) => {
                          switch (key.toUpperCase()) {
                            case "YOUNGDRIVER":
                              return {
                                label: t(value),
                                value: "youngDriver",
                              };
                            case "YOUNGDRIVER2":
                              return {
                                label: t(value),
                                value: "youngDriver2",
                              };
                            case "NOAGEDRIVER":
                              return {
                                label: t(value),
                                value: "noAgeFeeDriver",
                              };
                            case "SENIORDRIVER":
                              return {
                                label: t(value),
                                value: "seniorDriver",
                              };
                            default:
                              return {
                                label: "",
                                value: "",
                              };
                          }
                        })
                        .filter((option) => option !== null)}
                    />
                  </div>
                </div>
                <AppButton
                  color={"primary"}
                  type={"submit"}
                  endIcon={<SearchIcon />}
                  className="h-14 w-full"
                >
                  <p className={`w-full text-center`}>{translate("Search")}</p>
                </AppButton>
              </AppCard>
            </>
          ) : (
            <>
              {AppSettings?.pageTitle && (
                <>
                  <span className="text-white font-bold text-xl">
                    {t("Book Online")}
                  </span>
                  <span className="text-white font-bold text-xl">-</span>
                  <span className="text-white font-bold text-xl">
                    {pageTitleLabel}
                  </span>
                  <br />
                  <br />
                </>
              )}
              <AppCard className="flex flex-col bg-white w-full box-border justify-center items-center p-4 gap-4 py-8">
                <div className="flex xl:flex-row gap-2 flex-col w-full">
                  <div className="md:grow flex md:flex-row gap-2 flex-col min-w-[0rem] md:min-w-[25rem]">
                    <AppInputSwitcher
                      required={true}
                      className={"w-full"}
                      value={pickupLocation}
                      label={translate("pick up location")}
                      key={"pickUpLocation"}
                      options={locationDetail?.map((e: Location) => {
                        const optionValue =
                          AppSettings?.showLocationWebDescription
                            ? locationLang === "it" && e.publicWebDescription
                              ? e.publicWebDescription
                              : locationLang === "en" &&
                                e.publicWebDescriptionEN &&
                                AppSettings?.showLocationWebDescription
                              ? e.publicWebDescriptionEN
                              : e.locationName
                            : e.locationCode +
                              " - " +
                              e.locationName +
                              " " +
                              e.locationNumber +
                              " " +
                              e.locationAddress +
                              " " +
                              e.locationCity +
                              " " +
                              e.zipCode;

                        let icon = <LocationCityIcon color="primary" />;
                        if (e.isAirport) {
                          icon = <FlightTakeoffIcon htmlColor="blue" />;
                        } else if (e.isRailway) {
                          icon = <TrainIcon htmlColor="green" />;
                        }
                        return {
                          label: optionValue,
                          value: optionValue,
                          icon,
                        };
                      })}
                      type="select"
                      variant={
                        AppSettings?.notOpenKeyboardForLocation && smallScreen
                          ? "with-search-Location"
                          : "with-search"
                      }
                      onchange={(e) => {
                        if (isDroppingAtSameLocation) {
                          setReservationDestination(e);
                        }
                        setPickupLocation(e);
                        setStartDate(null);
                        setEndDate(null);
                      }}
                    />
                    {!isDroppingAtSameLocation && (
                      <AppInputSwitcher
                        required={true}
                        value={reservationDestination}
                        label={translate("drop off location")}
                        key={"dropOffLocation"}
                        options={locationDetail.map((e) => {
                          const optionValue =
                            AppSettings?.showLocationWebDescription
                              ? locationLang === "it" && e.publicWebDescription
                                ? e.publicWebDescription
                                : locationLang === "en" &&
                                  e.publicWebDescriptionEN &&
                                  AppSettings?.showLocationWebDescription
                                ? e.publicWebDescriptionEN
                                : e.locationName
                              : e.locationCode +
                                "-" +
                                e.locationName +
                                " " +
                                e.locationNumber +
                                " " +
                                e.locationAddress +
                                " " +
                                e.locationCity +
                                " " +
                                e.zipCode;
                          let icon = <LocationCityIcon color="primary" />;
                          if (e.isAirport) {
                            icon = <FlightTakeoffIcon htmlColor="blue" />;
                          } else if (e.isRailway) {
                            icon = <TrainIcon htmlColor="green" />;
                          }
                          return {
                            label: optionValue,
                            value: optionValue,
                            icon,
                          };
                        })}
                        type="select"
                        variant="with-search"
                        onchange={(e) => {
                          setReservationDestination(e);
                          setEndDate(null);
                        }}
                      />
                    )}
                  </div>
                  <AppInputSwitcher
                    sx={{
                      svg: { color: "primary" },
                      input: { color: "primary" },
                      label: { color: "primary" },
                      backgroundColor: "primary",
                    }}
                    disableOnDay={
                      pickupLocation && locationDetail.length
                        ? Object.keys(
                            locationDetail
                              .filter((e) => {
                                const optionValue =
                                  AppSettings?.showLocationWebDescription
                                    ? locationLang === "it" &&
                                      e.publicWebDescription
                                      ? e.publicWebDescription
                                      : locationLang === "en" &&
                                        e.publicWebDescriptionEN &&
                                        AppSettings?.showLocationWebDescription
                                      ? e.publicWebDescriptionEN
                                      : e.locationName
                                    : e.locationCode +
                                      "-" +
                                      e.locationName +
                                      " " +
                                      e.locationNumber +
                                      " " +
                                      e.locationAddress +
                                      " " +
                                      e.locationCity +
                                      " " +
                                      e.zipCode;
                                return (
                                  optionValue?.toLowerCase() ===
                                  pickupLocation?.toLowerCase()
                                );
                              })[0]
                              ?.openings?.map((e) => {
                                return e.dayOfTheWeek % 7;
                              })
                              .reduce(
                                (e, v) => {
                                  delete e[v];
                                  return e;
                                },
                                {
                                  1: true,
                                  2: true,
                                  3: true,
                                  4: true,
                                  5: true,
                                  6: true,
                                  7: true,
                                }
                              )
                          ).map((e) => parseInt(e))
                        : []
                    }
                    required={true}
                    className="w-full grow md:min-w-72"
                    label={translate("reservation dates")}
                    key={"reservationDates"}
                    options={[]}
                    startDate={reservationStartDate}
                    endDate={reservationEndDate}
                    minDate={addDays(new Date(), minRentalAllowedDay)}
                    maxDate={
                      reservationStartDate
                        ? addDays(
                            new Date(reservationStartDate?.toString()),
                            maxRentalAllowedDay
                          )
                        : addDays(new Date(), 365)
                    }
                    type="date"
                    variant="range"
                    onchange={(selectedDateRange) => {
                      if (selectedDateRange[0]) {
                        setStartDate(
                          setTime(
                            locationDetail
                              .filter((e) => {
                                const optionValue =
                                  AppSettings?.showLocationWebDescription
                                    ? locationLang === "it" &&
                                      e.publicWebDescription
                                      ? e.publicWebDescription
                                      : locationLang === "en" &&
                                        e.publicWebDescriptionEN &&
                                        AppSettings?.showLocationWebDescription
                                      ? e.publicWebDescriptionEN
                                      : e.locationName
                                    : e.locationCode +
                                      "-" +
                                      e.locationName +
                                      " " +
                                      e.locationNumber +
                                      " " +
                                      e.locationAddress +
                                      " " +
                                      e.locationCity +
                                      " " +
                                      e.zipCode;
                                return (
                                  optionValue?.toLowerCase() ===
                                  pickupLocation?.toLowerCase()
                                );
                              })[0]
                              ?.openings.filter(
                                (e) =>
                                  getDay(selectedDateRange[0]) ===
                                  e.dayOfTheWeek % 7
                              )[0]?.startTime || "",
                            selectedDateRange[0]
                          )
                        );
                      } else {
                        setStartDate(null);
                      }
                      if (selectedDateRange[1]) {
                        setEndDate(
                          setTime(
                            locationDetail
                              .filter((e) => {
                                const optionValue =
                                  AppSettings?.showLocationWebDescription
                                    ? locationLang === "it" &&
                                      e.publicWebDescription
                                      ? e.publicWebDescription
                                      : locationLang === "en" &&
                                        e.publicWebDescriptionEN &&
                                        AppSettings?.showLocationWebDescription
                                      ? e.publicWebDescriptionEN
                                      : e.locationName
                                    : e.locationCode +
                                      "-" +
                                      e.locationName +
                                      " " +
                                      e.locationNumber +
                                      " " +
                                      e.locationAddress +
                                      " " +
                                      e.locationCity +
                                      " " +
                                      e.zipCode;
                                return (
                                  optionValue?.toLowerCase() ===
                                  reservationDestination.toLowerCase()
                                );
                              })[0]
                              ?.openings.filter(
                                (e) =>
                                  getDay(selectedDateRange[1]) ===
                                  e.dayOfTheWeek % 7
                              )[0]?.startTime || "",
                            selectedDateRange[1]
                          )
                        );
                      } else {
                        setEndDate(null);
                      }
                    }}
                  />{" "}
                  <AppInputSwitcher
                    required={true}
                    disabled={!reservationStartDate}
                    className="w-full  min-w-[5rem]"
                    label={translate("pick up time")}
                    key={"pickUpTime"}
                    enableTimesInRange={getEnabledTimeSlots(
                      locationDetail,
                      pickupLocation,
                      reservationStartDate
                    )}
                    value={reservationStartDate}
                    options={[]}
                    type="time"
                    variant="slot"
                    onchange={(e) => {
                      setStartDate(e);
                    }}
                  />
                  <AppInputSwitcher
                    enableTimesInRange={getEnabledDropoffTimeSlots(
                      locationDetail,
                      reservationDestination,
                      reservationEndDate
                    )}
                    disabled={!reservationEndDate}
                    required={true}
                    className="min-w-[5rem]"
                    label={translate("drop off time")}
                    key={"dropOffTime"}
                    options={[]}
                    value={reservationEndDate}
                    type="time"
                    variant="slot"
                    onchange={(e) => {
                      setEndDate(e);
                    }}
                  />
                  {/*     {AppSettings?.showMacroGroupSelection && (
                    <AppInputSwitcher
                      required={false}
                      className={"w-full"}
                      type="select"
                      variant="with-search"
                      label={translate("Macro Group")}
                      options={macroGroupOptions?.map((e) => e.label) || []}
                      value={selectedMacroGroup}
                      onchange={(e) => {
                        setSelectedMacroGroup(e);
                      }}
                    />
                  )}*/}
                  {AppSettings?.showMacroGroupSelection && (
                    <AppInputSwitcher
                      required={false}
                      className={"w-full"}
                      type="select"
                      variant="with-search"
                      label={translate("Macro Group")}
                      options={macroGroupOptions?.map((e) => e.label) || []}
                      value={selectedMacroGroup}
                      onchange={(e) => {
                        const selectedOption = macroGroupOptions.find(
                          (option) => option.label === e
                        );
                        if (selectedOption) {
                          setSelectedMacroGroup(selectedOption.label);
                          setSelectedMacroGroupWebCode(selectedOption.WebCode);
                        }
                      }}
                    />
                  )}
                  {mountedRef.current &&
                  reservationSourceEnabled === true &&
                  reservationSourceForUser.length > 0 ? (
                    <div className="flex">
                      <AppInputSwitcher
                        required={true}
                        className="w-full min-w-[12rem]"
                        label={bookingResSourceForReservedArea}
                        key={"reservationPlatform"}
                        options={reservationSourceForUser?.map(
                          (e) => e["description"]
                        )}
                        type="select"
                        variant="with-search"
                        onchange={(e) => {
                          setReservationPlatform(e);
                        }}
                        value={reservationPlatform}
                      />
                    </div>
                  ) : (
                    AppSettings?.showReservationGroupSelection && (
                      <div className="flex">
                        <AppInputSwitcher
                          required={true}
                          className="w-full min-w-[12rem]"
                          label={bookingReservationSource}
                          key={"reservationPlatform"}
                          options={reservationSourceOptions?.map(
                            (e) => e["Ragione Sociale textRS"]
                          )}
                          value={reservationPlatform}
                          type="select"
                          variant="with-search"
                          onchange={(e) => {
                            setReservationPlatform(e);
                          }}
                        />
                      </div>
                    )
                  )}
                  {AppSettings?.showWebCodeInput && (
                    <AppInputSwitcher
                      required={false}
                      autoComplete="off"
                      className="min-w-[10rem]"
                      label={codiceLabel}
                      key={"Voucher Code"}
                      placeholder={codiceLabel}
                      options={[]}
                      value={agreementCoupon}
                      type="text"
                      variant="slot"
                      onchange={(e) => {
                        setagreementCoupon(e);
                      }}
                    />
                  )}
                  {/* Modal for invalid discount code */}
                  {showModal && validateAgreementCoupon && (
                    <div
                      className="modal-overlay"
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1001",
                      }}
                    >
                      <div
                        className="modal-content"
                        style={{
                          background: "white",
                          width: "300px",
                          padding: "15px",
                          borderRadius: "8px",
                          textAlign: "center",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          zIndex: "1000",
                        }}
                      >
                        <p>
                          {translate(
                            "The discount code you have inserted is not valid.Please insert a valid discount code to proceed."
                          )}
                        </p>
                        <button
                          onClick={() => setShowModal(false)}
                          className="close-button"
                          style={{
                            backgroundColor: "#17a2b8",
                            color: "white",
                            border: "none",
                            padding: "10px 20px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                        >
                          {translate("Close")}
                          {/*Close*/}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-row gap-2 w-full flex-wrap">
                  {AppSettings?.locationReturnEnable && (
                    <div className="min-w-fit max-w-fit">
                      <AppStandardMultiSelectCheckBox
                        value={[
                          isDroppingAtSameLocation
                            ? translate("drop at same location")
                            : "",
                        ]}
                        onChange={(e) => {
                          setIsDroppingAtSameLocation(
                            !isDroppingAtSameLocation
                          );
                          if (e.length) {
                            setReservationDestination(pickupLocation);
                          }
                        }}
                        options={[translate("drop at same location")]}
                        color={
                          AppSettings?.secondaryAsBackgroundForButtons
                            ? "secondaryColor"
                            : "primary"
                        }
                      />
                    </div>
                  )}

                  <div>
                    <AppRadioButton
                      required={true}
                      value={isAgeLimitSelected}
                      color={
                        AppSettings?.secondaryAsBackgroundForButtons
                          ? "secondaryColor"
                          : "primary"
                      }
                      onChange={(e) => {
                        setIsAgeLimitSelected(e);
                      }}
                      options={Object.entries(ageCriteriaData)
                        .map(([key, value]) => {
                          switch (key.toUpperCase()) {
                            case "YOUNGDRIVER":
                              return {
                                label: t(value),
                                value: "youngDriver",
                              };
                            case "YOUNGDRIVER2":
                              return {
                                label: t(value),
                                value: "youngDriver2",
                              };
                            case "NOAGEDRIVER":
                              return {
                                label: t(value),
                                value: "noAgeFeeDriver",
                              };
                            case "SENIORDRIVER":
                              return {
                                label: t(value),
                                value: "seniorDriver",
                              };
                            default:
                              return {
                                label: "",
                                value: "",
                              };
                          }
                        })
                        .filter((option) => option !== null)}
                    />
                  </div>
                  <AppButton
                    type={"submit"}
                    endIcon={<SearchIcon />}
                    className={`h-14 md:float-right md:ml-auto ${
                      smallScreen || istabletScreen ? "w-full" : "w-[10%]"
                    }`}
                    color={"primary"}
                  >
                    <p className={`w-full text-center`}>
                      {translate("Search")}
                    </p>
                  </AppButton>
                </div>
              </AppCard>
            </>
          )}
        </form>
      </main>
    </ThemeProvider>
  );
}
export default BookingForm;
